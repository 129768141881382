const Footer = function () {
  this.init();
  return this;
};

Footer.prototype = {
  init: function () {
    $.each($('.featured .featured_item'), () => {
      if (TurnerToons.user_platform != 'other' && !$(this).hasClass('platform_all')) {
        if (!$(this).hasClass('platform_' + TurnerToons.user_platform)) {
          $(this).remove();
        }
      }

      if ($(this).attr('desktop_game')) {
        if (TurnerToons.device == 'desktop' && $(this).attr('desktop_game') == '1') {
          $(this).find('.web-game-link-device-tablet, [web-game-link-device-tablet]').remove();
          $(this).find('.web-game-link-device-smartphone, [web-game-link-device-smartphone]').remove();
        } else if (TurnerToons.device == 'tablet' && $(this).attr('tablet_game') == '1') {
          $(this).find('.web-game-link-device-desktop, [web-game-link-device-desktop]').remove();
          $(this).find('.web-game-link-device-smartphone, [web-game-link-device-smartphone]').remove();
        } else if (TurnerToons.device == 'smartphone' && $(this).attr('smartphone_game') == '1') {
          $(this).find('.web-game-link-device-desktop, [web-game-link-device-desktop]').remove();
          $(this).find('.web-game-link-device-tablet, [web-game-link-device-tablet]').remove();
        } else {
          $(this).remove();
        }
      }
    });

    $('.footer_navigation a.link_overlay, .more-about-cookies').on('click', function (e) {
      e.preventDefault();
      window.scrollTo(0, 0);
      $('.popup').removeClass('active');
      $('.popup_content_' + $(this).attr('pos')).addClass('active');
    });

    $('.js_close_popups').on('click', function () {
      $('.popup').removeClass('active');
    });

    //cookie policy
    function setCookie(cname, cvalue, exdays, path) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + '; ' + (path != '' ? 'path=' + path + '; ' : '') + expires;
    }

    function getCookie(cname) {
      const name = cname + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
      }
      return '';
    }

    function cookieIsAccepted() {
      const policy = getCookie('cookiePolicy');
      if (policy == 'accepted') {
        return true;
      }
      return false;
    }

    function checkCookie() {
      if (cookieIsAccepted()) {
        $('.cookie_popup').remove();
      } else {
        $('.cookie_popup').show();
      }
    }

    checkCookie();

    $('.cookie_popup .agree-button').on('click', function (e) {
      e.preventDefault();
      if (!cookieIsAccepted()) {
        setCookie('cookiePolicy', 'accepted', 365, '/');
        $('.cookie_popup .agree-button').html(TurnerToons.COOKIE_POLICY.hide_button_txt);
        $('.cookieInfoTitle').html(TurnerToons.COOKIE_POLICY.title_2);
        $('.cookieInfoBody').html(TurnerToons.COOKIE_POLICY.body_2);
      } else {
        $('.cookie_popup').remove();
      }
    });

    function handleHashTrigger() {
      /*
       *  https://jira.jollywise.co.uk/browse/BOOMWSXIV-897
       *  all # based privacy, cookies etc links (not in footer) to obey the cms and how its setup.
       *  if its hash - use hash, external, use external, else use real url.
       *
       *  link types are :
       *  url : external link
       *  text : hash based
       *  text_page : real url
       */
      const hash = window.location.hash.substring(1);
      const linkObj = TurnerToons.FOOTER_TRIGGERS.filter((o) => {
        return o.trigger === hash;
      })[0];
      if (linkObj && linkObj.type === 'text_page') {
        window.location.replace('/' + linkObj.trigger);
      } else {
        $('a[data-trigger="' + hash + '"]').click();
      }
    }

    /**
     * This intercepts a hash based link and triggers it - on page load
     *
     * http://boomerang.imac/#terms
     * https://staging-boomerangtv-uk.turner-apps.com/#terms
     */
    if (window.location.hash) {
      handleHashTrigger();
    }
  },
};

export { Footer };
