import storage from 'simplestorage.js';

export default class LikeButton {
  constructor({ cookiePolicy, getURL, postURL, $button, type, id }) {
    /**
     * Conversion of Rich's old like button to ES6
     *
     * Likes are stored in simpleStore root object : site_likes
     * Format is {category: {id: 1}}
     */
    this.busy = false;
    this.cookiePolicy = cookiePolicy;
    this.getURL = getURL + '/' + type + '/' + id;
    this.postURL = postURL;
    this.$button = $button;
    this.type = type;
    this.id = id;
    if ($button.length > 0 && storage.canUse()) {
      this.init();
    } else {
      console.info('Likes are disabled:: localstorage not usable');
    }
  }

  init() {
    this.buttonClick = this.onButtonClick.bind(this);
    this.storedLikes = storage.get(STORAGE_KEY) || {};
    if (!this.storedLikes[this.type]) {
      this.storedLikes[this.type] = {};
      if (this.cookiePolicy.performanceCookiesEnabled) {
        storage.set(STORAGE_KEY, this.storedLikes);
      }
    }

    if (this.getHasLiked()) {
      this.$button.addClass('liked');
    }
    this.retrieveLikes();
  }

  getHasLiked() {
    return this.storedLikes[this.type][this.id] === 1;
  }

  setHasLiked() {
    this.storedLikes[this.type][this.id] = 1;
    if (this.cookiePolicy.performanceCookiesEnabled) {
      storage.set(STORAGE_KEY, this.storedLikes);
    }
    this.$button.addClass('liked');
  }

  onButtonClick(e) {
    e.preventDefault();
    /**
     * Clicking the button will hit api to register a like
     */

    // https://jollywise.atlassian.net/browse/BOINGWEB-203
    // Boom + cartoonito have emoji based like system - Boing doesn't
    // Adjustment needed to legacy to get the like/view logic working again
    if (!this.busy && !this.getHasLiked()) {
      this.busy = true;
      $.ajax({
        url: this.postURL,
        method: 'POST',
        dataType: 'json',
        data: {
          id: this.id,
          type: this.type,
          like_type: 'LIKE',
        },
      })
        .done((res) => {
          this.$button.off('click', this.buttonClick);
          const likes = res?.likes?.LIKE || 0;
          if (likes > 0) {
            this.setHasLiked();
            this.renderLikes(likes);
          } else {
            /**
             * something has gone wrong as response from
             * server should always be greater than 1
             */
          }
        })
        .fail((error) => {
          // failed
          console.error('Send likes api call failed');
          this.busy = false;
        });
    }
  }

  retrieveLikes() {
    return $.ajax({
      url: this.getURL,
      method: 'GET',
      dataType: 'json',
    })
      .done((response) => {
        // https://jollywise.atlassian.net/browse/BOINGWEB-203
        // Boom + cartoonito have emoji based like system - Boing doesn't
        // Adjustment needed to legacy to get the like/view logic working again

        const likes = response.LIKE || 0;
        this.renderLikes(likes);
        this.$button.on('click', this.buttonClick);
      })
      .fail((error) => {
        console.error('Retrieve likes api call failed - button removed');
        this.$button.remove();
      });
  }

  renderLikes(likes) {
    const likeFormatted = this._format(likes);
    this.$button.find('.count').text(likeFormatted);
  }

  _format(num) {
    const si = [
      { value: 1e18, symbol: 'E' },
      { value: 1e15, symbol: 'P' },
      { value: 1e12, symbol: 'T' },
      { value: 1e9, symbol: 'G' },
      { value: 1e6, symbol: 'M' },
      { value: 1e3, symbol: 'k' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    num = parseFloat(num);
    const digits = num > 100000 && num < 1000000 ? 0 : 1;
    for (i = 0; i < si.length; i++) {
      if (num >= si[i].value) {
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
      }
    }
    return (num + 0).toFixed(digits).replace(rx, '$1');
  }
}
const STORAGE_KEY = 'site_likes';
