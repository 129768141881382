import 'core-js/es/array/iterator';

import { setOneTrustLikeVisibility } from 'modules/viewsAndLikesActions';
import { ensightenTrackingSetup } from 'modules/ensighten_tracking/EnsightenTracking';
import { startApp } from 'main';
import { getLogOptions } from 'constants/logging';

/**
 * https://stackoverflow.com/questions/3698200/window-onload-vs-document-ready
 * https://stackoverflow.com/questions/799981/document-ready-equivalent-without-jquery/55649686#55649686
 */

const { enabled: log, clr: logClr, prep } = getLogOptions({ id: 'bootstrap' });

export const handleDOMReady = function (callback) {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback());
  } else if (document.attachEvent) {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState != 'loading') {
        callback();
      }
    });
  }
};

/**
 * Bootstrap listens to both onetrust_init and DOM load events
 * It handles OT setup and media depending on OT state
 */
const bootstrap = (function () {
  'use strict';
  let pageId;
  let device;
  let mediaInitialised = false;

  const oneTrustBannerInDom = () => {
    const otBanner = document.getElementById('onetrust-banner-sdk');
    if (otBanner && window.getComputedStyle(otBanner, null).getPropertyValue('display') == 'block') {
      return true;
    }
    return false;
  };

  const attachOneTrustListeners = () => {
    log && console.log(`${prep} attach OneTrust listeners`, logClr);
    document.body.addEventListener('click', function (evt) {
      const { id, classList } = evt.target;
      const classCheckList = 'save-preference-btn-handler,ot-pc-refuse-all-handler';
      const idCheckList = 'onetrust-accept-btn-handler,accept-recommended-btn-handler,onetrust-reject-all-handler,ot-pc-refuse-all-handler';
      if (classList) {
        for (const classClick of classList) {
          if (classClick && classCheckList.includes(classClick)) {
            location.reload();
          }
        }
      }
      if (id && id !== '' && idCheckList.includes(id)) {
        location.reload();
      }
    });

    /**
     * respond to OneTrust prefs open and close : pause/play videos
     * cant pause/play games as they would need to be individually adjusted to respond to play/pause
     */
    const optanonPopup = document.getElementsByClassName('optanon-toggle-display');
    for (let i = 0; i < optanonPopup.length; i++) {
      log && console.log(`${prep} attached to: ${optanonPopup[i].className}`, logClr);
      optanonPopup[i].addEventListener('click', () => {
        window.bitmovinPlayer && window.bitmovinPlayer.pause();
      });
    }
    const optanonPopupClose = document.getElementById('close-pc-btn-handler');
    if (optanonPopupClose) {
      log && console.log(`${prep} attached to: ${optanonPopupClose.className}`, logClr);
      optanonPopupClose.addEventListener('click', () => {
        window.bitmovinPlayer && window.bitmovinPlayer.play();
      });
    }
  };

  // function handleOneTrustInit() {
  //   const cookiePolicy = window.TurnerToons.COOKIE_POLICY || {};
  //   cookiePolicy.oneTrustStarted = true;
  //   const domLoaded = cookiePolicy.domLoaded;
  //   const oneTrustBannerExists = oneTrustBannerInDom();
  //
  //   log && console.log(`${prep} +++++ handleOneTrustInit | domLoaded = ${domLoaded} | oneTrustBannerExists = ${oneTrustBannerExists}`, logClr);
  //
  //   if (domLoaded && !mediaInitialised) {
  //     log && console.log(`${prep} OneTrust started | Dom loaded, mediaInitialised = false`, logClr);
  //     startApp(true);
  //   } else if (!domLoaded && oneTrustBannerExists) {
  //     log && console.log(`${prep} OneTrust started | DOM loaded : ${domLoaded} | oneTrustBannerExists : ${oneTrustBannerExists}`, logClr);
  //     attachOneTrustListeners();
  //   }
  // }

  function handleDomLoad() {
    const cookiePolicy = window.TurnerToons.COOKIE_POLICY || {};
    cookiePolicy.domLoaded = true;
    cookiePolicy.oneTrustStarted = true;
    const oneTrustActive = cookiePolicy.oneTrustActive;
    const oneTrustStarted = cookiePolicy.oneTrustStarted;

    log && console.log(`${prep} +++++ handleDomLoad | oneTrustActive = ${oneTrustActive} | oneTrustStarted = ${oneTrustStarted}`, logClr);
    log && console.log(`${prep} +++++ OnetrustActiveGroups`, logClr, window.OnetrustActiveGroups);

    /**
     * CARTOONITO-261 : we do not need the check for oneTrustStarted
     */

    attachOneTrustListeners();
    startApp(true);

    // if (oneTrustActive && !oneTrustStarted) {
    //   attachOneTrustListeners();
    //   const oneTrustBannerVisible = document.cookie.match(/^(.*;)?\s*OptanonAlertBoxClosed\s*=\s*[^;]+(.*)?$/) === null;
    //   if (oneTrustBannerVisible) {
    //     log && console.log(`${prep} DOM load : OneTrust active, banner visible`, logClr);
    //     startApp(false);
    //   } else {
    //     log && console.log(`${prep} DOM load : OneTrust active, banner hidden`, logClr);
    //     startApp(false);
    //   }
    // } else if (oneTrustActive && oneTrustStarted && !mediaInitialised) {
    //   log && console.log(`${prep} DOM load : oneTrustActive ${oneTrustActive} | oneTrustStarted ${oneTrustStarted}`, logClr);
    //   startApp(true);
    // }
  }

  const getCookiePolicyData = () => {
    const cookiePolicy = window.TurnerToons.COOKIE_POLICY || {};
    cookiePolicy.oneTrustBannerExists = oneTrustBannerInDom();
    const otActiveGroups = window.OnetrustActiveGroups ? window.OnetrustActiveGroups.split(',') : [];
    cookiePolicy.performanceCookiesEnabled = otActiveGroups.indexOf('pf') !== -1;
    cookiePolicy.functionalCookiesEnabled = otActiveGroups.indexOf('bb') !== -1;
    cookiePolicy.advertisingCookiesEnabled = otActiveGroups.indexOf('ad') !== -1;
    return cookiePolicy;
  };

  const addGfkTracking = (pf) => {
    const devEnvs = ['dev-', 'staging-', '.local', '.dev', 'localhost'];

    const isDevEnv =
      devEnvs.find((value) => {
        return window.location.host.indexOf(value) !== -1;
      }) || false;

    // url: "//es-config.sensic.net/s2s-web.js", // for production
    // url: '//es-config-preproduction.sensic.net/s2s-web.js', // for preproduction
    /* eslint-disable */
    var gfkS2sConf = {
      media: 'boingweb',
      url: isDevEnv ? 'https://es-config-preproduction.sensic.net/s2s-web.js' : 'https://es-config.sensic.net/s2s-web.js',
      type: 'WEB',
      optin: pf, //optional
      logLevel: isDevEnv ? 'debug' : 'none', //optional
    };

    (function (w, d, c, s, id, v) {
      if (d.getElementById(id)) {
        return;
      }

      w.gfkS2sConf = c;
      w[id] = {};
      w[id].agents = [];
      var api = ['playStreamLive', 'playStreamOnDemand', 'stop', 'skip', 'screen', 'volume', 'impression'];
      w.gfks = (function () {
        function f(sA, e, cb) {
          return function () {
            sA.p = cb();
            sA.queue.push({ f: e, a: arguments });
          };
        }
        function s(c, pId, cb) {
          var sA = { queue: [], config: c, cb: cb, pId: pId };
          for (var i = 0; i < api.length; i++) {
            var e = api[i];
            sA[e] = f(sA, e, cb);
          }
          return sA;
        }
        return s;
      })();
      w[id].getAgent = function (cb, pId) {
        var a = {
          a: new w.gfks(
            c,
            pId || '',
            cb ||
              function () {
                return 0;
              }
          ),
        };
        function g(a, e) {
          return function () {
            return a.a[e].apply(a.a, arguments);
          };
        }
        for (var i = 0; i < api.length; i++) {
          var e = api[i];
          a[e] = g(a, e);
        }
        w[id].agents.push(a);
        return a;
      };

      var lJS = function (eId, url) {
        var tag = d.createElement(s);
        var el = d.getElementsByTagName(s)[0];
        tag.id = eId;
        tag.async = true;
        tag.type = 'text/javascript';
        tag.src = url;
        el.parentNode.insertBefore(tag, el);
      };

      if (c.hasOwnProperty(v)) {
        lJS(id + v, c[v]);
      }
      lJS(id, c.url);
    })(window, document, gfkS2sConf, 'script', 'gfkS2s', 'visUrl');
    /* eslint-enable */

    setTimeout(() => {
      const agent = gfkS2s.getAgent();
      /**Please insert custom parameters (key/value) as described here: https://confluence-docu.gfk.com/display/SENSIC/Client+specific+customizations */
      const splitPath = window.trackingVariables.pageName.split('/');
      const customParams = { c1: splitPath[0], c2: splitPath[1] ? splitPath[1] : 'homepage' };
      agent.impression('default', customParams);
    }, 500);
  };

  const initialiseMedia = (cookiePolicy) => {
    if (mediaInitialised) {
      return;
    }
    mediaInitialised = true;
    const { oneTrustActive, performanceCookiesEnabled, functionalCookiesEnabled, advertisingCookiesEnabled, oneTrustBannerExists } = getCookiePolicyData();

    // testing tracking
    window.TurnerToons.gfkTrackingActive && addGfkTracking(cookiePolicy.performanceCookiesEnabled);

    // Triggers Ads to display
    // TR-56
    // I hate timeouts
    setTimeout(() => {
      if (window.initTagGpt) {
        window.initTagGpt();
      }
    }, 500);

    if (log) {
      console.log(`${prep} INITIALISE MEDIA`, logClr);
      console.log(
        `%c+ oneTrustActive ${oneTrustActive} \n+ BannerExists ${oneTrustBannerExists} \n+ performanceCookies ${performanceCookiesEnabled} \n+ functionalCookie ${functionalCookiesEnabled} \n+ advertisingCookies ${advertisingCookiesEnabled}`,
        logClr
      );
      console.log(`${prep} pageId ${pageId} | device ${device.type}`, logClr);
    }

    setOneTrustLikeVisibility(cookiePolicy);

    const hasPreroll = pageId === 'gameplay' || pageId === 'quizplay';
    if (hasPreroll) {
      initialiseGamePlayerVideo(cookiePolicy, oneTrustBannerExists);
    }

    if (!oneTrustActive) {
      /**
       * BOOMWSXIV-1042 : If oneTrust is disabled in cms we default to enabling tracking
       */
      ensightenTrackingSetup();
      addBitmovinEnsightenPlayer(true);
    } else {
      if (ensightenTrackingIsEnabled()) {
        ensightenTrackingSetup();
      }
      /**
       * Bitmovin player handles whether to play ad internally based on cookiePolicy.advertisingCookiesEnabled
       */
      if (performanceCookiesEnabled) {
        // ad + performance enabled : pre-roll then the video plays with the Adobe data firing
        // performance enabled, ad disabled : video plays with the Adobe data firing
        if (ensightenTrackingIsEnabled()) {
          addBitmovinEnsightenPlayer(true);
        } else {
          addBitmovinEnsightenPlayer();
        }
      } else {
        // performance disabled, ad enabled : pre-roll then the video plays with NO Adobe data firing
        // ad + performance disabled : video plays with NO Adobe data firing
        if (!oneTrustBannerExists) {
          addBitmovinEnsightenPlayer();
        }
      }
    }
  };

  const initialiseGamePlayerVideo = (cookiePolicy, oneTrustBannerExists) => {
    // BOOMWSXIV-1011 - functionality for gameplay_page mobile/tablet related games / exiting game modal overlay
    const { oneTrustActive, performanceCookiesEnabled, functionalCookiesEnabled, advertisingCookiesEnabled } = cookiePolicy;
    if ($('body').hasClass('gameplay_page--mobile-container') && device.type !== 'desktop') {
      import(/* webpackChunkName: "mobilegamecontainer" */ 'modules/MobileGameContainer')
        .then(({ default: Component }) => {
          log && console.log(`${prep} Bootstrap :: Loaded MobileGameContainer`, logClr);
          new Component({ os: device.family, isQuiz: pageId === 'quizplay', playPreRoll: advertisingCookiesEnabled, cookiePolicy });
        })
        .catch((error) => {
          console.error('An error occurred while loading the Mobile Game Container component : ', error);
        });
    } else if ($('body').hasClass('gameplay_page') && !$('body').hasClass('campaigns')) {
      const checkForFlash = window.checkFlash;
      let checkForFlashTriggered = false;
      const setupGame = () => {
        log && console.log(`${prep} checkForFlashTriggered ${checkForFlashTriggered}`, logClr);
        if (!checkForFlashTriggered) {
          checkForFlashTriggered = true;
          checkForFlash();
        }
      };

      if (oneTrustBannerExists) {
        log && console.log(`${prep} oneTrustBannerExists - delay gameplay`, logClr);
      } else if (performanceCookiesEnabled && advertisingCookiesEnabled) {
        addPrerollPlayer(setupGame);
      } else if (performanceCookiesEnabled && !advertisingCookiesEnabled) {
        setupGame();
      } else if (!performanceCookiesEnabled && advertisingCookiesEnabled) {
        addPrerollPlayer(setupGame);
      } else if (oneTrustActive) {
        setupGame();
      } else {
        // final fallback - if no OT then play prerolls
        addPrerollPlayer(setupGame);
      }
    }
  };

  const addPrerollPlayer = (setupGame) => {
    log && console.log(`${prep} addPrerollPlayer`, logClr);
    window.bitmovinPlayer = true;
    getBitmovinPlayer()
      .then((Component) => {
        window.bitmovinPlayer = new Component({ avsData: window.bitmovinData, prerollOnly: true });
        document.addEventListener('bitmovin_finished', setupGame);
      })
      .catch((error) => {
        console.error('An error occurred while loading the BitmovinPlayer');
      });
  };

  const addBitmovinEnsightenPlayer = (addEnsightenTracking = false) => {
    const isDeviceGamePlayPage = $('body').hasClass('gameplay_page--mobile-container');
    const isDesktopGamePlayPage = $('body').hasClass('gameplay_page');
    const playerInDOM = document.getElementById('bitmovin-player') !== null;

    const valid = !isDeviceGamePlayPage && !isDesktopGamePlayPage && !window.bitmovinPlayer && playerInDOM;

    if (valid) {
      log && console.log(`${prep} addBitmovinEnsightenPlayer`, logClr);
      getBitmovinPlayer()
        .then((Component) => {
          window.bitmovinPlayer = new Component({ avsData: window.avs_video });
          if (addEnsightenTracking) {
            window.bitmovinPlayer.addEnsightenTracking();
          }
        })
        .catch((error) => {
          console.error('An error occurred while loading the BitmovinPlayer');
        });
    } else {
      log && console.log(`${prep} addBitmovinEnsightenPlayer ignored`, logClr);
    }
  };

  const ensightenTrackingIsEnabled = () => {
    return typeof window.ensightenTracking != 'undefined' && window.ensightenTracking.type == 'new';
  };

  const getBitmovinPlayer = () => {
    return import(/* webpackChunkName: "bitmovinplayer" */ 'modules/bitmovin/BitmovinPlayer').then(({ default: Component }) => {
      log && console.log(`${prep} Loaded BitmovinPlayer`, logClr);
      return Component;
    });
  };

  return {
    start: function (id) {
      /**
       * Listen for both Dom load and onetrust_init events and respond accordingly
       */
      pageId = id;
      /**
       * CARTOONITO-261 : we do not need to listen for 'onetrust_init' because changing cookie prefs forces a page reload
       */
      // window.addEventListener('onetrust_init', handleOneTrustInit);
      window.addEventListener('load', handleDomLoad);
    },
    initialiseMedia: function (detectedDevice, cookiePolicy) {
      device = detectedDevice;
      setTimeout(() => {
        initialiseMedia(cookiePolicy);
      }, 1000);
    },
    getCookiePolicy: function () {
      return getCookiePolicyData();
    },
  };
})();

export { bootstrap };
