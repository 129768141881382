export const TURNER_METADATA = window.turner_metadata || false;

export const parseData = (data) => {
  // Needs to convert data to lowercase
  // I think it aslo needs to remove certain characters / not sure what tho at the moment
  return (data || '').toLowerCase();
};

export const getTrackingArray = () => {
  return window.turner_metadata && window.turner_metadata.trackAction ? window.turner_metadata.trackAction : [];
};

export const getDomain = () => {
  const hostname = window.location.hostname.replace('www.', '');
  const pathname = window.location.pathname;
  const domain = hostname + pathname;
  return parseData(domain);
};

export const INTERACTION_DATA = {
  interaction: 'internal campaign click',
  interactionevent: 1,
  internalcampaignevent: 1,
  internalcampaign: 'nvs',
};

export const INTERACTION_WEB_GAME_DATA = {
  englishname: '',
  interaction: 'game content click',
  interactionevent: 1,
  contentclickevent: 1,
  gameclickevent: 1,
};

export const INTERACTION_VIDEO_GAME_DATA = {
  englishname: '',
  interaction: 'video content click',
  interactionevent: 1,
  contentclickevent: 1,
  videoclickevent: 1,
};

export const AD_TEMPLATE = {
  ad_id: '',
  ad_duration: 0,
  ad_type: 'preroll',
};

export const VIDEO_PLAYER_DATA = {
  content_type: 'clip',
  content_id: '',
  content_duration: 0,
  content_name: '',
  content_showName: 'boomerang',
  contenttype: 'main',
  pageName: '',
  section: 'shows',
  subsection: 'boomerang',
  franchise: 'boomerang',
  friendlyName: '',
  region: '',
  country: '',
  brand: '',
};

export const AD_DATA = {
  ad_id: '',
  ad_duration: 0,
  ad_type: 'preroll',
};
