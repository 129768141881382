export const getContentData = () => {
  const content = TurnerToons.pageContentData || {};
  const { title = '', game_category = '', name_english = 'nvs', web_game_categories = [] } = content;

  // gives fallback if show is null/undefined
  const show = (content.show ? content.show : {}) || {};

  const { name_english: show_name_english = 'nvs' } = show;
  let cat = 'nvs';
  if (Array.isArray(web_game_categories) && web_game_categories.length > 0) {
    cat = web_game_categories[0].name;
  }
  const gameCategory = cat ? cat.toLowerCase() : 'nvs';
  return {
    title: title.toLowerCase(),
    nameEnglish: name_english ? name_english.toLowerCase() : 'nvs',
    showNameEnglish: show_name_english ? show_name_english.toLowerCase() : '',
    gameCategory,
  };
};
