/**
 * App Screenshots Carousel
 * This handles the slider carousel - not often used
 * Enabled via CMS eg:
 * admin/slider/show/homepage : Slider type: Full-width (max 10 items)
 */
import SwiperCore, { Navigation, Lazy, Autoplay } from 'swiper';
import { Swiper } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/lazy/lazy.scss';
import { getLogOptions } from 'constants/logging';

SwiperCore.use([Navigation, Lazy, Autoplay]);

const AppScreenshotsCarousel = function (pageId) {
  const direction = document.dir !== undefined ? document.dir : document.getElementsByTagName('html')[0].getAttribute('dir');
  const isRTL = direction === 'rtl';
  this.log = getLogOptions({ id: 'carousels' });
  const carouselExists = document.getElementsByClassName('app-screenshots-swiper-container').length > 0;
  if (pageId === 'appplay' && carouselExists) {
    // only render AppScreenshotsCarousel if on appplay page and app-screenshots-swiper-container exists
    this.init(isRTL);
  }
};

AppScreenshotsCarousel.prototype = {
  init: function (isRTL) {
    const params = {
      preloadImages: false,
      lazy: true,
      direction: 'horizontal',
      loop: false,
      spaceBetween: 36,
      slidesPerView: 3,
      slidesPerGroup: 1,
      navigation: {
        prevEl: '.swiper_button_prev',
        nextEl: '.swiper_button_next',
        disabledClass: 'disabled',
      },
    };
    if (isRTL) {
      params.dir = 'rtl';
    }
    this.log.enabled && console.log(`${this.log.prep} AppScreenshotsCarousel started`, this.log.clr, params);
    new Swiper('.app-screenshots-swiper-container', params);
  },
};
export { AppScreenshotsCarousel };
