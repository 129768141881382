/*
 * https://jollywise.atlassian.net/browse/BOOMWSXIV-1484
 *
 * IE11 polyfills
 *
 */

/**
 * Number.isNaN() polyfill
 * Swiper JS v6 : IE11 no longer supported
 * Number.isNaN was causing site to not render!
 *
 * Adding this pollyfill stops this. However swiper slides dont render properly (large)!
 *
 * https://github.com/nolimits4web/swiper/issues/3847
 * https://github.com/nolimits4web/swiper/issues/3698
 */
Number.isNaN =
  Number.isNaN ||
  function isNaN(input) {
    return typeof input === 'number' && input !== input;
  };

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }
    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}
