import { debounce } from 'utils/debounce';

const headerAnimation = (function () {
  'use strict';

  //----------------------------------------------
  // Const's
  // Setup the corresponding hand image and sound
  const _handSound = {
    1: {
      img_pos: '0 0',
      sound_bite: {
        sound_mp3: {
          type: 'mp3',
          src: 'sounds/hand_sound_1.mp3',
        },
        sound_ogg: {
          type: 'ogg',
          src: 'sounds/hand_sound_1.ogg',
        },
        sound_wav: {
          type: 'wav',
          src: 'sounds/hand_sound_1.wav',
        },
      },
    },
    2: {
      img_pos: '-85px 0',
      sound_bite: {
        sound_mp3: {
          type: 'mp3',
          src: 'sounds/hand_sound_2.mp3',
        },
        sound_ogg: {
          type: 'ogg',
          src: 'sounds/hand_sound_2.ogg',
        },
        sound_wav: {
          type: 'wav',
          src: 'sounds/hand_sound_2.wav',
        },
      },
    },
    3: {
      img_pos: '-170px 0',
      sound_bite: {
        sound_mp3: {
          type: 'mp3',
          src: 'sounds/hand_sound_3.mp3',
        },
        sound_ogg: {
          type: 'ogg',
          src: 'sounds/hand_sound_3.ogg',
        },
        sound_wav: {
          type: 'wav',
          src: 'sounds/hand_sound_3.wav',
        },
      },
    },
    4: {
      img_pos: '-255px 0',
      sound_bite: {
        sound_mp3: {
          type: 'mp3',
          src: 'sounds/hand_sound_4.mp3',
        },
        sound_ogg: {
          type: 'ogg',
          src: 'sounds/hand_sound_4.ogg',
        },
        sound_wav: {
          type: 'wav',
          src: 'sounds/hand_sound_4.wav',
        },
      },
    },
    5: {
      img_pos: '-340px 0',
      sound_bite: {
        sound_mp3: {
          type: 'mp3',
          src: 'sounds/hand_sound_5.mp3',
        },
        sound_ogg: {
          type: 'ogg',
          src: 'sounds/hand_sound_5.ogg',
        },
        sound_wav: {
          type: 'wav',
          src: 'sounds/hand_sound_5.wav',
        },
      },
    },
  };

  //----------------------------------------------
  // Variables
  // Gif related vars
  let _triggerGifs = false;
  let _gifTimerLog = 0;
  let _gifTimeouts = [];

  // Setup global var to set the animation state
  let _triggerAnimation = true;

  // setInterval vars
  let logoTimer, gifTimer;

  // the passed in carousel selector and type
  let carouselSelector;
  let carouselType;
  //----------------------------------------------
  // Helper Functions to set stuff up for the animations
  function _clearAllIntervals(clearAll = false) {
    if (clearAll) {
      clearInterval(gifTimer);
      clearInterval(logoTimer);
      logoTimer = null;
      gifTimer = null;
    }
  }

  // function _setLogoInterval(trigger = false) {
  //   if (trigger) {
  //     logoTimer = setInterval(function() {
  //       _triggerLogo($('#logo_push'));
  //     }, 15000);
  //   }
  // }

  function _setGifInterval(trigger = false) {
    if (trigger) {
      _gifTimerLog = 0;
      gifTimer = setInterval(function () {
        if (_gifTimerLog == 2) {
          _triggerGif();
          _gifTimerLog = 0;
        } else {
          _triggerGif(false);
          _gifTimerLog++;
        }
      }, 5000);
    }
  }

  //----------------------------------------------
  // Page visibility api
  // Set the name of the hidden property and the change event for visibility
  let hidden, visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  // Check if page is hidden
  function handleVisibilityChange(firstTrigger = false) {
    if (document[hidden]) {
      _clearAllIntervals(true);
    } else {
      if ($(window).scrollTop() > $('#header').height()) {
        if (!$('body').hasClass('gameplay_page')) {
          _setGifInterval(true);
        }
      } else {
        _triggerAll($('#logo_push'), carouselSelector);
      }
    }
  }

  //---------------------------------------------
  // Functions
  // function _triggerSound(target) {
  //   // Setup html audio tag
  //   let _audioTag = document.createElement('audio');
  //   for (let key in target.sound_bite) {
  //     if (Object.prototype.hasOwnProperty.call(target.sound_bite, key)) {
  //       const source = document.createElement('source');
  //       source.src = '/static/base/' + target.sound_bite[key].src;
  //       source.type = 'audio/' + target.sound_bite[key].type;
  //       _audioTag.appendChild(source);
  //     }
  //   }
  //   _audioTag.id = 'sound_bite';
  //   _audioTag.volume = 0.3;
  //   _audioTag.autoPlay = true;
  //
  //   const playPromise = _audioTag.play();
  //
  //   if (playPromise !== undefined) {
  //     playPromise
  //       .then(function() {
  //         _audioTag.play();
  //       })
  //       .catch(function(error) {
  //         // console.error(error);
  //       });
  //   }
  //
  //   setTimeout(function() {
  //     _audioTag.innerHTML = '';
  //   }, 1000);
  // }

  function _triggerGif(all = true) {
    let showCarouselGif = carouselSelector.find('[data-gif-image]');

    for (let i = 0; i < _gifTimeouts.length; i++) {
      clearTimeout(_gifTimeouts[i]);
    }
    _gifTimeouts = [];

    if (all) {
      showCarouselGif.each(function () {
        let item = $(this);
        if (carouselType === 'lazy') {
          const isLoaded = item.hasClass('swiper-lazy-loaded');
          if (isLoaded) {
            _updateItemAll(item);
          }
        } else {
          _updateItemAll(item);
        }
      });
    } else {
      let randomNumber = Math.floor(Math.random() * showCarouselGif.length);
      let item = $(showCarouselGif[randomNumber]);
      if (carouselType === 'lazy') {
        const isLoaded = item.hasClass('swiper-lazy-loaded');
        if (isLoaded) {
          _updateItemSingle(item);
        }
      } else {
        _updateItemSingle(item);
      }
    }
  }

  function _updateItemAll(item) {
    item.attr('src', item.data('gif-image'));
    _gifTimeouts.push(
      setTimeout(function () {
        item.attr('src', item.data('jpeg-image'));
        _triggerAnimation = true;
      }, 2500)
    );
  }
  function _updateItemSingle(item) {
    item.attr('src', item.data('gif-image'));
    _gifTimeouts.push(
      setTimeout(function () {
        item.attr('src', item.data('jpeg-image'));
      }, 2500)
    );
  }

  // function _triggerLogo(logo) {
  // // Get both img path + sound bite
  // let currentSelected = Math.floor(Math.random() * Object.keys(_handSound).length) + 1;
  // // Trigger the hand push header logo
  // logo.addClass('active');
  // $('#logo_hand').css('background-position', _handSound[currentSelected].img_pos);
  //
  // // Trigger sound bite
  // _triggerSound(_handSound[currentSelected]);
  //
  // // Allow for the animation to play then revert back to normal logo
  // setTimeout(function() {
  //   logo.removeClass('active');
  // }, 2500);
  // }

  function _triggerAll(logo, carousel) {
    _triggerAnimation = false;
    // _triggerLogo($('#logo_push'));
    // _setLogoInterval(true);
    // Trigger the show carousel gif swap
    if (_triggerGifs) {
      _triggerGif();
      _setGifInterval(true);
    }
  }

  function _onClickTrigger(logo) {
    logo.on('click', function (e) {
      e.preventDefault();
      if (_triggerAnimation) {
        // Clear the interval
        _clearAllIntervals(true);
        // trigger the animation
        _triggerAll($('#logo_push'), carouselSelector);
      }
    });
  }

  function _onMouseTrigger(carousel) {
    carousel.on({
      mouseenter: function () {
        _triggerAnimation = false;
        // Clear the interval
        _clearAllIntervals(true);
      },
      mouseleave: function () {
        if ($(window).scrollTop() > $('#header').height()) {
          logoTimer = null;
          _setGifInterval(true);
        } else {
          _triggerAnimation = true;
          // _setLogoInterval(true);
          _setGifInterval(true);
        }
      },
    });
  }

  function _onScrollEvent() {
    // We debouce the function call on scroll so it doesn't get fired out constantly
    $(window).on('scroll', function () {
      _clearAllIntervals(true);
    });
    $(window).on(
      'scroll',
      debounce(function () {
        if ($(window).scrollTop() > $('#header').height()) {
          if (!$('body').hasClass('gameplay_page')) {
            _setGifInterval(true);
          }
        } else {
          // _setLogoInterval(true);
          _setGifInterval(true);
        }
      }, 300)
    );
  }

  //---------------------------------------------
  // Return init function to trigger animations
  return {
    init: function (logo_selector, carousel_selector, carousel_type) {
      carouselSelector = carousel_selector;
      carouselType = carousel_type;
      // We need to set a timeout to account for the loading overlay
      setTimeout(function () {
        if (carousel_selector.find('[data-gif-image]').length > 0) {
          _triggerGifs = true;
        }
        // See if browser addEventListener or the Page Visibility API
        if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
          // If not trigger animations as norm
          _triggerAll(logo_selector, carousel_selector);
        } else {
          // Handle page visibility change
          document.addEventListener(visibilityChange, handleVisibilityChange, false);
          // trigger initial check
          handleVisibilityChange();
        }
        // Stop the interval if user goes past the header
        _onScrollEvent();
        // Trigger the animation on logo click
        _onClickTrigger(logo_selector);
        if (TurnerToons.device !== 'smartphone') {
          // Stop the animation if the show carousel is hovered over
          _onMouseTrigger(carousel_selector);
        }
      }, 1000);
    },
  };
})();

export { headerAnimation };
