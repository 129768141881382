const CustomActions = function (options) {
  const base = TurnerToons.FrontEndBaseURL;
  const friendlyURLS = TurnerToons.FrontEndFriendlyURLs;
  const knocknockFriendly = TurnerToons.FrontEndKnockKnockShowFriendlyUrl;
  const animalsBase = `${base}${friendlyURLS.animals}/${friendlyURLS.animals_potw}`;
  const letscreateBase = `${base}${friendlyURLS.letscreate}/${friendlyURLS.letscreate_gallery}`;
  const knockknockBase = `${base}shows/${knocknockFriendly}`;

  const loc = window.location;
  this.actions = {
    ACTION_SUBMIT_PET: function () {
      if (loc.href.indexOf(friendlyURLS.animals_potw) === -1) {
        window.location = `${animalsBase}/#/submit`;
      } else {
        window.location.hash = '#/submit';
      }
    },
    ACTION_VIEW_PET_GALLERY: function () {
      if (loc.href.indexOf(friendlyURLS.animals_potw) === -1) {
        window.location = `${animalsBase}/#/index`;
      } else {
        window.location.hash = '#/index';
      }
    },
    ACTION_SUBMIT_CRAFT: function () {
      if (loc.href.indexOf(friendlyURLS.letscreate_gallery) === -1) {
        window.location = `${letscreateBase}/#/submit`;
      } else {
        window.location.hash = '#/submit';
      }
    },
    ACTION_VIEW_CRAFT_GALLERY: function () {
      if (loc.href.indexOf(friendlyURLS.letscreate_gallery) === -1) {
        window.location = `${letscreateBase}/#/index`;
      } else {
        window.location.hash = '#/index';
      }
    },
    ACTION_SUBMIT_JOKE: function () {
      if (loc.href.indexOf(knocknockFriendly) === -1) {
        window.location = `${knockknockBase}/#/submit`;
      } else {
        window.location.hash = '#/submit';
      }
    },
    ACTION_VIEW_JOKE_GALLERY: function () {
      if (loc.href.indexOf(knocknockFriendly) === -1) {
        window.location = `${knockknockBase}/#/index`;
      } else {
        window.location.hash = '#/index';
      }
    },
  };

  this.init();
};
CustomActions.prototype = {
  init: function () {
    $('[data-action]').on('click', (e) => {
      const actionId = e?.currentTarget?.dataset?.action ?? false;
      if (actionId) {
        this.actions[actionId]();
      }
    });
  },
};

export { CustomActions };
