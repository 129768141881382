import { getTrackingArray, INTERACTION_DATA, INTERACTION_WEB_GAME_DATA, INTERACTION_VIDEO_GAME_DATA } from 'modules/ensighten_tracking/constants/ensightenConstants';

const trackInteraction = ({ type, baseData, overrideData, log }) => {
  const { englishname = 'nvs', englishshowname = 'nvs' } = overrideData;
  let interactionData = null;
  if (type == 'gameClick') {
    interactionData = { ...INTERACTION_WEB_GAME_DATA, ...baseData };
    interactionData.englishname = englishname.toLowerCase();
    interactionData.gametitle = englishname.toLowerCase();
    interactionData.franchise = englishshowname.toLowerCase();
    interactionData.contenttype = 'featured content';

    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * contenttitle is clicked content, not current page title
     */
    interactionData.contenttitle = interactionData.gametitle;
  } else if (type == 'videoClick') {
    interactionData = { ...INTERACTION_VIDEO_GAME_DATA, ...baseData };
    interactionData.englishname = 'nvs';
    interactionData.videotitle = englishname.toLowerCase();
    interactionData.franchise = englishshowname.toLowerCase();
    interactionData.contenttype = 'featured content';

    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * contenttitle is clicked content, not current page title
     */
    interactionData.contenttitle = interactionData.videotitle;
  } else {
    interactionData = { ...INTERACTION_DATA, ...baseData };
    interactionData.franchise = englishname.toLowerCase();
    interactionData.contenttype = 'featured content';

    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * contenttitle is clicked content, not current page title
     */
    interactionData.contenttitle = interactionData.franchise;
  }

  log.enabled && console.log(`${log.prep} trackInteraction`, log.clr, interactionData);
  getTrackingArray().push({ type: 'interaction', data: interactionData });
};

export default trackInteraction;
