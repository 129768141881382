/* eslint-disable */

/* version 0.05 */
/*POSSIBLE SETUP
    $('.carousel_widget').each(function(index, item) {
    t.carousel_array.push( new CarouselWidget( { $carousel:$(item),
                                                   wrapper_classname:'.carousel_wrapper',
                                                   children_classname:'.carousel_item',
                                                   area_classname:'.carousel_area',
                                                   auto_scroll:false,
                                                   swipe_enabled: true,
                                                   swipe_hint_enabled:true,
                                                   auto_scroll_if_no_touch:true,
                                                   child_width_percentage:31.333,
                                                   child_margin_percentage:3,
                                                   resize_callback:carousel_threeup_callback,
                                                   touchstart_callback:my_touchstart_callback,
                                                   touchend_callback:my_touchend_callback,
                                                   no_transforms:true,
                                                   loop_scroll:true
                                                } )
    );
*/
const CarouselWidget = function (config) {
  var t = this;
  // console.log('@@@@@@@@@@@@@@@@@@@@@\nCarouselWidget', config, '\n@@@@@@@@@@@@@@@@@@@@@');
  t.config = config;
  t.type = config.type;
  if (config.type === 'FEED') {
    this.feedItemType = config.feedItemType;
    // console.info('CarouselWidget created for', this.feedItemType);
  } else {
    // console.info('CarouselWidget type', config.type);
  }
  t.$carouselContainer = t.config.$carousel;
  t.$carouselArea = t.$carouselContainer.children(t.config.area_classname);

  t.$carouselWrapper = t.$carouselArea.children(t.config.wrapper_classname);
  t.$carouselChildren = t.$carouselWrapper.children(t.config.children_classname);

  t.$nav_buttons = t.$carouselContainer.find('.nav_button');

  t.$nav_left = t.$carouselContainer.find('.nav_left');
  t.$nav_right = t.$carouselContainer.find('.nav_right');

  t.$blips = t.$carouselContainer.find('.blip');

  t.child_width_percentage = t.config.child_width_percentage || 100;
  t.child_margin_percentage = t.config.child_margin_percentage || 0;

  t.currentSection = 0;
  t.maxSections = t.$carouselChildren.length - 1;
  t.onscreen_count = t.config.onscreen_count || 1;

  t.autoscroll = t.config.auto_scroll || false;
  t.interaction_interupt = false;
  t.swipe_enabled = t.config.swipe_enabled || false;
  t.no_transforms = t.config.no_transforms || false;
  t.swipe_hint_enabled = t.config.swipe_hint_enabled || false;
  t.auto_scroll_if_no_touch = t.config.auto_scroll_if_no_touch || false;
  t.loop_scroll = t.config.loop_scroll || false;

  t.resize_callback = t.config.resize_callback || $.noop;
  t.touchstart_callback = t.config.touchstart_callback || $.noop;
  t.touchend_callback = t.config.touchend_callback || $.noop;

  t.autoScrollingTimer = null;
  t.swipeHintTimer = null;
  t.RTLMODE = false;
  //t.init();
  // console.log('CarouselWidget',t.$carouselChildren);
  return t;
};

CarouselWidget.prototype = {
  init: function () {
    var t = this;
    if ($('html[dir="rtl"]').length > 0) {
      t.RTLMODE = true;
    }

    t.$blips.each(function (index, item) {
      $(item).data('index', index);
    });

    t.$blips.on('click', function (e) {
      e.preventDefault();
      t.gotoFrame($(this).data('index'));
      t.$blips.removeClass('active');
      $(this).addClass('active');
    });

    if (t.$blips.length < 2) {
      t.$blips.remove();
    }
    // console.log('CarouselWidget', this.RTLMODE);
    t.set_size();

    $(window).on('resize orientation', function (e) {
      t.set_size();
    });

    t.$nav_left.on('click', function (e) {
      e.preventDefault();
      if (!t.RTLMODE) {
        t.prev($(this));
      } else {
        t.next($(this));
      }
    });

    t.$nav_right.on('click', function (e) {
      e.preventDefault();
      if (!t.RTLMODE) {
        t.next($(this));
      } else {
        t.prev($(this));
      }
    });

    t.$nav_buttons.each(function (index, item) {
      if (!t.RTLMODE) {
        if ($(item).hasClass('nav_left')) {
          $(item).addClass('disabled');
        }
      } else {
        if ($(item).hasClass('nav_right')) {
          $(item).addClass('disabled');
        }
      }
    });

    if (t.$carouselChildren.length < 2) {
      t.$nav_buttons.remove();
    }

    if (window.Modernizr.touch && t.$carouselChildren.length > 1 && t.swipe_enabled && !t.RTLMODE) {
      t.setup_touch();
    }

    return t;
  },

  set_size: function () {
    var t = this;
    //restart autoscrolling if window resize/orientation
    t.interaction_interupt = false;

    try {
      t.resize_callback(t);
    } catch (e) {}
    /**
     * Ash - I didnt really understand what was happening below! All 3 conditions do same thing?
     * Had to adjust for arabic
     */
    t.section_width = Math.ceil(t.$carouselArea.outerWidth() * (t.child_width_percentage / 100));
    t.section_margin = Math.ceil(t.$carouselArea.outerWidth() * (t.child_margin_percentage / 100));
    // - 1 : Account for the overlap of content_items.
    // probably a better way to work out the widths but I don't want to go through this
    if (t.type == 'SMALL' || t.type == 'FEED') {
      // - 1 : Account for the overlap of content_items.
      // probably a better way to work out the widths but I don't want to go through this
      if (!t.RTLMODE) {
        t.$carouselChildren.css({ width: t.section_width, 'margin-right': t.section_margin });
        t.$carouselChildren.last().css({ 'margin-right': 0 });
      } else {
        t.$carouselChildren.css({ width: t.section_width, 'margin-left': t.section_margin });
        t.$carouselChildren.last().css({ 'margin-left': 0 });
      }
    } else if (!t.RTLMODE) {
      t.$carouselChildren.css({ width: t.section_width, 'margin-right': t.section_margin });
      t.$carouselChildren.last().css({ 'margin-right': 0 });
    } else {
      t.$carouselChildren.css({ width: t.section_width, 'margin-left': t.section_margin });
      t.$carouselChildren.last().css({ 'margin-left': 0 });
    }

    t.total_width = 0;
    let item_width = 0;
    let item_height = 0;
    t.$carouselChildren.each(function (index, item) {
      item_width = $(item).outerWidth(true);
      // console.log('item_width', item_width);
      item_height = $(item).outerHeight(true);
      t.total_width += item_width;
    });

    if (!t.RTLMODE) {
      t.pixel_scroll_limit = (t.total_width - t.$carouselArea.outerWidth()) * -1;
    } else {
      t.pixel_scroll_limit = t.total_width - t.$carouselArea.outerWidth();
    }
    /*
     *  STEPHEN : FEED carousel amends to hack fix variable padding height in feed blocks depending on number of items in feed!!
     *
     *  if (TurnerToons.device !== 'smartphone') {
     *      options.height = (item_height) + 'px';
     *  }
     */
    let carousel_width = t.total_width + 50 + 'px'; // default, original
    if (this.type === 'FEED') {
      let widthFix = 0;
      for (let i = 0; i <= 9; i++) {
        widthFix += item_width;
      }
      carousel_width = widthFix + 50 + 'px';
    }

    const options = {
      width: carousel_width,
      position: 'relative',
      left: 0,
      top: 0,
    };
    // if (TurnerToons.device !== 'smartphone') {
    //     options.height = (item_height) + 'px';
    // }
    // console.log('set_size', this.total_width);
    t.$carouselWrapper.css(options);

    if (window.Modernizr.csstransforms && t.no_transforms == false) {
      t.$carouselWrapper.css({ transform: 'translate(0px, 0px)' });
    }
    t.currentSection = 0;

    t.onscreen_count_offset = t.onscreen_count - 1;
    if (t.onscreen_count_offset < 0) {
      t.onscreen_count_offset = 0;
    }

    if (t.auto_scroll_if_no_touch && t.$carouselChildren.length > 1 && window.Modernizr.touch == false) {
      t.autoscroll = true;
    }

    try {
      clearTimeout(t.autoScrollingTimer);
    } catch (e) {}
    if (t.autoscroll == true) {
      t.autoScrollingTimer = setTimeout(function () {
        t.autoscroll_init();
      }, 7000);
    }

    t.check_buttons();
  },
  next: function (button) {
    var t = this;

    if (t.currentSection < t.maxSections - t.onscreen_count_offset) {
      t.currentSection++;

      t.update_translate_carousel();
      t.interaction_interupt = true;
    } else if (t.loop_scroll == true) {
      t.currentSection = 0;

      t.update_translate_carousel();
      t.interaction_interupt = true;
    }
    t.check_buttons();
  },
  prev: function (button) {
    var t = this;

    if (t.currentSection > 0) {
      t.currentSection--;

      t.update_translate_carousel();
      t.interaction_interupt = true;
    } else if (t.loop_scroll == true) {
      t.currentSection = t.maxSections - t.onscreen_count_offset;

      t.update_translate_carousel();
      t.interaction_interupt = true;
    }
    t.check_buttons();
  },
  gotoFrame: function (_pos) {
    var t = this;

    t.currentSection = _pos;

    t.update_translate_carousel();

    t.interaction_interupt = true;

    t.check_buttons();
  },
  autoscroll_init: function () {
    var t = this;

    if (t.autoscroll == true && t.interaction_interupt == false) {
      t.currentSection++;
      if (t.currentSection < 0) {
        t.currentSection = 0;
      }

      var screen_count = 100 / t.child_width_percentage - 2;
      if (parseInt(100 / t.child_width_percentage) == 1) {
        screen_count = 0;
      } else if (screen_count < 1) {
        screen_count = 1;
      }

      if (t.currentSection > t.maxSections - t.onscreen_count_offset - screen_count) {
        t.currentSection = 0;
      }

      t.update_translate_carousel();

      t.$blips.removeClass('active');

      t.$blips.each(function (index, item) {
        if ($(item).data('index') == t.currentSection) {
          $(item).addClass('active');
        }
      });

      try {
        clearTimeout(t.autoScrollingTimer);
      } catch (e) {}
      t.autoScrollingTimer = setTimeout(function () {
        t.autoscroll_init();
      }, 7000);
    }
  },
  update_translate_carousel: function () {
    var t = this;

    if (t.currentSection - t.onscreen_count < t.maxSections) {
      if (window.Modernizr.csstransforms && t.no_transforms == false) {
        if (!t.RTLMODE) {
          t.$carouselWrapper.css({
            transform: 'translate(' + (t.section_width + t.section_margin) * t.currentSection * -1 + 'px, 0px)',
          });
        } else {
          t.$carouselWrapper.css({
            transform: 'translate(' + (t.section_width + t.section_margin) * t.currentSection + 'px, 0px)',
          });
        }
      } else {
        if (!t.RTLMODE) {
          t.$carouselWrapper[0].style.left = -((t.section_width + t.section_margin) * t.currentSection) + 'px';
        } else {
          t.$carouselWrapper[0].style.left = (t.section_width + t.section_margin) * t.currentSection + 'px';
        }
      }
    }
  },
  check_buttons: function () {
    var t = this;

    t.$nav_buttons.each(function (index, item) {
      if (!t.RTLMODE) {
        if ($(item).hasClass('nav_right')) {
          if (t.currentSection == t.maxSections - t.onscreen_count_offset) {
            $(this).addClass('disabled');
          } else {
            $(this).removeClass('disabled');
          }
        }

        if ($(item).hasClass('nav_left')) {
          if (t.currentSection < 1) {
            $(this).addClass('disabled');
          } else {
            $(this).removeClass('disabled');
          }
        }
      } else {
        if ($(item).hasClass('nav_left')) {
          if (t.currentSection == t.maxSections - t.onscreen_count_offset) {
            $(this).addClass('disabled');
          } else {
            $(this).removeClass('disabled');
          }
        }

        if ($(item).hasClass('nav_right')) {
          if (t.currentSection < 1) {
            $(this).addClass('disabled');
          } else {
            $(this).removeClass('disabled');
          }
        }
      }
    });

    if (t.maxSections < t.onscreen_count) {
      t.$nav_buttons.addClass('disabled');
    }

    t.$blips.removeClass('active');

    t.$blips.each(function (index, item) {
      if (t.currentSection == $(item).data('index')) {
        $(this).addClass('active');
      }
    });
  },

  setup_touch: function () {
    var t = this;

    t.startX = 0;
    t.startY = 0;
    t.count = 0;
    t.countY = 0;
    t.currentPosition = { left: 0 };
    t.direction = '';
    t.touching = false;

    t.$carouselWrapper.on('touchstart', function (e) {
      //e.preventDefault();
      t.interaction_interupt = false;
      t.startX = e.originalEvent.touches[0].pageX;
      t.startY = e.originalEvent.touches[0].pageY;
      t.currentPosition = t.$carouselWrapper.position();
      t.count = 0;
      t.countY = 0;
      t.touching = true;
      try {
        t.touchstart_callback(t);
      } catch (e) {}
    });

    t.$carouselWrapper.on('touchmove', function (e) {
      t.$carouselWrapper.addClass('no_transition');

      var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];

      if (t.startX > touch.pageX) {
        t.direction = 'left';
      } else {
        t.direction = 'right';
      }

      t.count = touch.pageX - t.startX;
      t.countY = touch.pageY - t.startY;

      /* Hacky swipe vs window scroll stuff, android chrome is lame */
      if (Math.abs(t.countY) < 5) {
        e.preventDefault();
      }

      var result = t.currentPosition.left + t.count;
      if (result > 0) {
        result = 0;
      } else if (t.pixel_scroll_limit > 0) {
        result = 0;
      } else if (result < t.pixel_scroll_limit) {
        result = t.pixel_scroll_limit;
      }

      if (window.Modernizr.csstransforms && t.no_transforms == false) {
        t.$carouselWrapper.css({ transform: 'translate(' + result + 'px, 0px)' });
      } else {
        t.$carouselWrapper[0].style.left = result + 'px';
      }
    });

    t.$carouselWrapper.on('touchend', function (e) {
      if (Math.abs(t.count) > 10) {
        e.preventDefault();
      }

      if (t.touching == true) {
        t.touching = false;
        t.$carouselWrapper.removeClass('no_transition');

        var position = t.check_pixel_position();

        position = position / ((t.section_width + t.section_margin) * (t.$carouselChildren.length - 1));
        position = Math.round(t.maxSections * position);

        if (position == t.currentSection) {
          if (Math.abs(t.count) > 30) {
            if (t.direction == 'left') {
              position++;
            } else {
              position--;
            }
          }

          if (position < 0) {
            position = 0;
          }

          if (t.onscreen_count_offset == 0) {
            if (position > t.maxSections - t.onscreen_count_offset) {
              position = t.maxSections - t.onscreen_count_offset;
            }
          } else {
            if (position > t.maxSections - t.onscreen_count_offset) {
              position = t.maxSections - t.onscreen_count_offset;
            }
          }
        }

        if (position < 0) {
          position = 0;
        }

        t.gotoFrame(position);
        try {
          t.touchend_callback(t);
        } catch (e) {}
      }
    });

    t.$carouselWrapper.off('touchcancel');

    if (t.swipe_hint_enabled) {
      try {
        clearTimeout(t.swipeHintTimer);
      } catch (e) {}
      t.swipeHintTimer = setTimeout(function () {
        t.swipe_hint();
      }, 5000);
    }
  },

  remove_touch: function () {
    var t = this;

    t.$carouselWrapper.off('touchstart');
    t.$carouselWrapper.off('touchmove');
    t.$carouselWrapper.off('touchend');
  },
  swipe_hint: function () {
    var t = this;

    t.currentPosition = t.$carouselWrapper.position();
    var result = t.currentPosition.left + t.count;

    if (t.autoscroll == false && t.interaction_interupt == false && window.Modernizr.touch == true && t.pixel_scroll_limit < -50 && t.swipe_hint_enabled) {
      var position = t.check_pixel_position();

      if (position == 0) {
        position = 100;
      } else {
        position = 0;
      }

      if (window.Modernizr.csstransforms && t.no_transforms == false) {
        t.$carouselWrapper.css({ transform: 'translate(' + position * -1 + 'px, 0px)' });
      } else {
        t.$carouselWrapper.css({ left: position * -1 });
      }

      try {
        clearTimeout(t.swipeHintTimer);
      } catch (e) {}
      t.swipeHintTimer = setTimeout(
        function () {
          t.swipe_hint();
        },
        position == 0 ? 5000 : 500
      );
    }
  },

  check_pixel_position: function () {
    var t = this,
      position = 0;
    if (window.Modernizr.csstransforms && t.no_transforms == false) {
      var matrix = t.$carouselWrapper.css('transform');
      matrix = matrix.match(/-?[0-9\.]+/g);
      position = Math.abs(parseInt(matrix[4]));
    } else {
      position = Math.abs(parseInt(t.$carouselWrapper.css('left')));
    }

    return position;
  },
};

// Exports
export { CarouselWidget };
