/**
 * Lazy Slider Carousel
 * This handles the slider carousel - not often used
 * Enabled via CMS eg:
 * admin/slider/show/homepage : Slider type: Full-width (max 10 items)
 */
import SwiperCore, { Navigation, Lazy, Autoplay, Pagination } from 'swiper';
import { Swiper } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/lazy/lazy.scss';
import { getLogOptions } from 'constants/logging';

SwiperCore.use([Navigation, Lazy, Autoplay, Pagination]);

const SliderCarouselLazy = function (pageId) {
  this.log = getLogOptions({ id: 'carousels' });
  const carouselExists = document.getElementsByClassName('slider-carousel-swiper-container').length > 0;
  const items = $('.main_carousel .carousel_item');
  this.itemsCount = items.length;
  if (carouselExists && this.itemsCount > 0) {
    this.sanitise(items);
    this.itemsCount > 1 ? this.initMulti() : this.initSingle();
    this.log.enabled && console.log(`${this.log.prep} SliderCarouselLazy started`, this.log.clr);
  }
};

SliderCarouselLazy.prototype = {
  sanitise: function (items) {
    // sanitise slider elements : removes non device specific elements
    $.each(items, () => {
      if (TurnerToons.user_platform != 'other' && !$(this).hasClass('platform_all')) {
        if (!$(this).hasClass('platform_' + TurnerToons.user_platform)) {
          $(this).remove();
        }
      }

      const $slide_information = $(this).find('.slide-information');
      if ($slide_information.attr('desktop_game')) {
        if (TurnerToons.device == 'desktop' && $slide_information.attr('desktop_game') == '1') {
          $slide_information.find('.web-game-link-device-tablet').remove();
          $slide_information.find('.web-game-link-device-smartphone').remove();
        } else if (TurnerToons.device == 'tablet' && $slide_information.attr('tablet_game') == '1') {
          $slide_information.find('.web-game-link-device-desktop').remove();
          $slide_information.find('.web-game-link-device-smartphone').remove();
        } else if (TurnerToons.device == 'smartphone' && $slide_information.attr('smartphone_game') == '1') {
          $slide_information.find('.web-game-link-device-desktop').remove();
          $slide_information.find('.web-game-link-device-tablet').remove();
        } else {
          $slide_information.parent().remove();
        }
      }
    });

    // wrap the main carousel items in an anchor with the href given to the child item that has a link
    items.each(function (i, el) {
      $(el).wrap('<a href="' + $(el).find('a').attr('href') + '" target="' + $(el).find('a').attr('target') + '"></a>');
    });
  },

  initSingle: function () {
    const options = {
      preloadImages: true,
      lazy: true,
      direction: 'horizontal',
      loop: false,
      autoHeight: true,
    };
    $('.main_carousel .slider-carousel-pagination').hide();
    $('.main_carousel .nav_right').hide();
    $('.main_carousel .nav_left').hide();
    new Swiper('.slider-carousel-swiper-container', options);
  },

  initMulti: function () {
    const options = {
      preloadImages: false,
      lazy: true,
      direction: 'horizontal',
      loop: true,
      autoHeight: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.nav_right',
        prevEl: '.nav_left',
      },
      pagination: {
        el: '.slider-carousel-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      },
    };
    new Swiper('.slider-carousel-swiper-container', options);
  },
};
export { SliderCarouselLazy };
