import storage from 'simplestorage.js';
import { getLogOptions } from 'constants/logging';

const { enabled: log, clr: logClr, prep } = getLogOptions({ id: 'viewsLikes' });

const showHide = ({ targetClassName, action = 'add', addition = 'like_button--hidden' }) => {
  const els = document.getElementsByClassName(targetClassName);
  log && console.log(`${prep} showHide ${targetClassName}`, logClr);
  if (els.length > 0) {
    log && console.log(`${prep} showHide ${els.length} element`, logClr);
    els.forEach((el) => {
      action === 'add' ? el.classList.add(addition) : el.classList.remove(addition);
    });
  }
};

// Like + Views buttons
export const setOneTrustLikeVisibility = (cookiePolicy) => {
  const oneTrustActive = cookiePolicy.oneTrustActive || false;
  const performanceCookiesEnabled = cookiePolicy.performanceCookiesEnabled || false;
  log && console.log(`${prep} setOneTrustLikeVisibility | oneTrustActive ${oneTrustActive} | performanceCookiesEnabled ${performanceCookiesEnabled}`, logClr);
  if (oneTrustActive) {
    if (performanceCookiesEnabled) {
      /**
       * Show like buttons
       */
      log && console.log(`${prep} Likes enabled`, logClr);
      showHide({ targetClassName: 'like_button', action: 'remove' });
    } else {
      /**
       * Hide like buttons
       */
      log && console.log(`${prep} Likes disabled`, logClr);
      storage.flush(); // clear localstorage
      document.cookie = 'language_redirect= ; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax;'; // zero cookie

      showHide({ targetClassName: 'like_button', action: 'add' });
    }
  }
};

export const setContentLikeViewVisibility = () => {
  // Hide Likes and Views if disabled on content type
  log && console.log(`${prep} setContentLikeViewVisibility`, logClr, TurnerToons.likesAndViews);
  if (document.getElementsByClassName('like_views_container')[0]) {
    let container = document.getElementsByClassName('like_views_container')[0];
    if (typeof TurnerToons.likesAndViews == 'object') {
      if (container.id.indexOf('web_game') !== -1 && TurnerToons.likesAndViews['web_game'] == 0) {
        container.classList.add('like_views_container--hidden');
      } else if (TurnerToons.likesAndViews[container.id] == 0) {
        container.classList.add('like_views_container--hidden');
      }
    }
  }
};
