import trackInteraction from 'modules/ensighten_tracking/interaction/ensightenInteraction';
import { getDomain } from 'modules/ensighten_tracking/constants/ensightenConstants';
import { trackPageview } from 'modules/ensighten_tracking/page/ensightenPageview';
import { getLogOptions } from 'constants/logging';

const log = getLogOptions({ id: 'tracking' });

const trackingIsEnabled = () => {
  const cookiePolicy = window.TurnerToons && window.TurnerToons.COOKIE_POLICY ? window.TurnerToons.COOKIE_POLICY : {};
  const oneTrustActive = cookiePolicy.oneTrustActive || false;
  const performanceCookiesEnabled = cookiePolicy.performanceCookiesEnabled || false;
  let isEnabled = true; // default to tracking enabled
  if (oneTrustActive) {
    isEnabled = performanceCookiesEnabled; // if oneTrustActive tracking enabled if performanceCookiesEnabled = true
  }
  return isEnabled;
};

const ensightenTrackingSetup = () => {
  const vars = window.trackingVariables;
  vars.presentationtemplate = TurnerToons.device == 'smartphone' ? 'mobile web' : TurnerToons.device;
  vars.pageName = getDomain();
  vars.contenttitle = vars.contenttitle ? vars.contenttitle : document.title.toLowerCase(); // exclude this from homepage, gamespage, videospage, clubpage, appspage

  if (TurnerToons.device != 'desktop') {
    if (window.orientation == 0 || window.orientation == 180) {
      vars.screenorientation = 'mobile|potrait';
    } else {
      vars.screenorientation = 'mobile|landscape';
    }

    window.addEventListener('resize', function () {
      if (window.orientation == 0 || window.orientation == 180) {
        vars.screenorientation = 'mobile|potrait';
      } else {
        vars.screenorientation = 'mobile|landscape';
      }
    });
  }

  if (!trackingIsEnabled()) {
    return;
  }

  const pageId = (window.gallery_config && window.gallery_config.pageId) || 'unknown';
  log.enabled && console.log(`${log.prep} ensightenTrackingSetup | trackingIsEnabled = true | pageId ${pageId}`, log.clr);

  triggerTracking('pageview');

  // If there are carousel items
  if ($('.shows_carousel_wrapper .swiper-wrapper .swiper-slide').length > 0) {
    $('.shows_carousel_wrapper .swiper-wrapper .swiper-slide a').each(function (index, item) {
      $(item).on('click', function () {
        const englishname = $(this).data('englishname');
        triggerTracking('interaction', { englishname });
      });
    });
  }

  // Featured Items in the header that are game content types -- When set to maincarousel
  if ($('.main_carousel .carousel_wrapper .carousel_item').length > 0) {
    if ($('.main_carousel .carousel_wrapper .carousel_item.content_item--web_game').length > 0) {
      $('.main_carousel .carousel_wrapper .carousel_item.content_item--web_game').each(function (index, item) {
        const trackData = {
          englishname: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        const parent = $(this).parent();
        parent.on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
  }

  // Related Items under the header that are game content types
  if ($('.carousel_widget.related').length > 0) {
    if ($('.carousel_widget.related .carousel_wrapper .content_item.content_item--web_game').length > 0) {
      $('.carousel_widget.related .carousel_wrapper .content_item.content_item--web_game').each(function (index, item) {
        const trackData = {
          englishname: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        const parent = $(this).parent();
        parent.on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
  }

  // Items under the header that are Show video content types
  if ($('.carousel_widget.pos_show_videos').length > 0) {
    if ($('.carousel_widget.pos_show_videos .carousel_wrapper .content_item.content_item--video').length > 0) {
      $('.carousel_widget.pos_show_videos .carousel_wrapper .content_item.content_item--video').each(function (index, item) {
        const trackData = {
          englishname: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        const parent = $(this).parent();
        parent.on('click', function () {
          triggerTracking('interactionVideoClick', trackData);
        });
      });
    }
  }

  // Featured Items in the header that are game content types
  if ($('.featured_header .featured_header_item').length > 0) {
    if ($('.featured_header .featured_header_item a.content_item--web_game').length > 0) {
      $('.featured_header .featured_header_item a.content_item--web_game').each(function (index, item) {
        const trackData = {
          englishname: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
    if ($('.featured_header .featured_header_item a.content_item--video').length > 0) {
      $('.featured_header .featured_header_item a.content_item--video').each(function (index, item) {
        const trackData = {
          englishname: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionVideoClick', trackData);
        });
      });
    }
  }
};

const triggerTracking = (type, data) => {
  if (!trackingIsEnabled()) {
    return;
  }

  const { feedcode, previouspage, screenorientation, pageviewevent, ...baseData } = window.trackingVariables;
  baseData.contenttitle = baseData.contenttitle === '' || baseData.contenttitle === null ? 'nvs' : baseData.contenttitle;

  switch (type) {
    case 'pageview':
      baseData.pageviewevent = 1;
      return trackPageview({ baseData, log });
    case 'interaction':
      return trackInteraction({ type: 'interactionClick', baseData, overrideData: data, log });
    case 'interactionGameClick':
      return trackInteraction({ type: 'gameClick', baseData, overrideData: data, log });
    case 'interactionVideoClick':
      return trackInteraction({ type: 'videoClick', baseData, overrideData: data, log });
  }
};

export { ensightenTrackingSetup };
