import { getTrackingArray } from 'modules/ensighten_tracking/constants/ensightenConstants';
import { getContentData } from 'modules/ensighten_tracking/utils';

const mergeCategoryData = ({ baseData }) => {
  const { details = {} } = window.gameCategory;
  baseData.gamecategory = details.name || 'nvs';
  baseData.gamecategorypage = 1;
};

const mergeGameData = ({ baseData }) => {
  const { title, nameEnglish, showNameEnglish, gameCategory } = getContentData();
  baseData.subsection = `games|${title}`;
  baseData.contenttype = 'game detail';
  baseData.gametitle = title;
  baseData.englishname = nameEnglish;
  baseData.gamedetailviewevent = 1;
  baseData.gamecategory = gameCategory;

  /*
   * https://jollywise.atlassian.net/browse/TAI-258
   * contenttitle is clicked content, not current page title
   */
  baseData.contenttitle = title || nameEnglish;
};

const mergeVideoData = ({ baseData }) => {
  const { title, nameEnglish, showNameEnglish } = getContentData();
  baseData.subsection = `video|${title}`;
  baseData.contenttype = 'video detail';
  baseData.videotitle = title;
  baseData.englishname = nameEnglish;
  baseData.videodetailviewevent = 1;

  /*
   * https://jollywise.atlassian.net/browse/TAI-258
   * contenttitle is clicked content, not current page title
   */
  baseData.contenttitle = title || nameEnglish;
};

export const trackPageview = ({ baseData, log }) => {
  // dont track pageview if we are in the letscreate app - as this is handled by the letscreate app
  const friendlyURLS = TurnerToons.FrontEndFriendlyURLs;
  const locationHash = window.location.hash;
  const isSectionLetsCreate = window.location.pathname.includes(friendlyURLS.letscreate);
  const hasLetsCreateHash = isSectionLetsCreate && locationHash && (locationHash.includes('submit') || locationHash.includes('index') || locationHash.includes('creation'));
  if (hasLetsCreateHash) {
    return;
  }

  const { section, subsection } = baseData;
  switch (section) {
    case 'home':
      baseData.contenttype = 'home page';
      break;
    case 'editorial':
      baseData.section = 'editorials';
      baseData.subsection = 'editorials|main';
      baseData.contenttype = 'editorials page';
      break;
    case 'shows':
      baseData.contenttype = 'shows page';
      break;
    case 'win':
    case 'campaigns':
      baseData.section = 'campaigns';
      baseData.subsection = 'campaigns|main';
      baseData.contenttype = 'campaigns page';
      break;
    case 'videos':
      baseData.contenttype = 'video page';
      if (!subsection.includes('|main')) {
        mergeVideoData({ baseData });
      }
      break;
    case 'games':
      baseData.contenttype = 'games page';
      if (subsection.includes('|category')) {
        mergeCategoryData({ baseData });
      } else if (!subsection.includes('|main')) {
        mergeGameData({ baseData });
      }
      break;
    default:
      break;
  }

  // if (subsection.includes('|main') && baseData.contenttype !== 'home page') {
  //   baseData.contenttype = 'section front';
  // }

  log.enabled && console.log(`${log.prep} trackPageview`, log.clr, baseData);
  getTrackingArray().push({ type: 'pageview', data: baseData });
};
