/**
 * https://gist.github.com/beaucharman/1f93fdd7c72860736643d1ab274fee1a
 */
export const debounce = (callback, wait) => {
  let timeout = null;
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

// function debounce (fn, wait) {
//   let t
//   return function () {
//     clearTimeout(t)
//     t = setTimeout(() => fn.apply(this, ...args), wait)
//   }
// }

// export const debounce = (func, wait, immediate) => {
//   let timeout;
//   return function() {
//     const context = this;
//     const args = arguments;
//     const later = function() {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     };
//     const callNow = immediate && !timeout;
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//     if (callNow) func.apply(context, args);
//   };
// }
