/* global __WATCH__:false, __SERVICE_WORKER__:false */
/*!
 *
 * Main site code
 *
 */
import '../scss/styles.scss';
import postscribe from 'postscribe';
import { getDevice } from 'utils/platformDetection';
import { App } from './App';
import { bootstrap, handleDOMReady } from 'bootstrap';
import { getLogOptions } from 'constants/logging';

// https://jollywise.atlassian.net/browse/BOOMWSXIV-1484
import 'utils/ie11-polyfills';

let domReady = false;
let device = false;
let pageId = (window.gallery_config && window.gallery_config.pageId) || 'unknown';
let app = new App();

const { enabled: log, clr: logClr, prep } = getLogOptions({ id: 'main' });

const checkDevice = () => {
  if (!device) {
    // ignore as first call to setDevice hasn't returned yet
    return;
  }
  log && console.log(`${prep} checkDevice`, logClr, device);
  if (device.type === 'unknown') {
    import(/* webpackChunkName: "platformdetection" */ './utils/platformMobileDetect')
      .then(({ default: getDeviceMD }) => {
        setDevice(getDeviceMD(TurnerToons.platforms));
      })
      .catch((error) => {
        console.error('An error occurred while loading the Mobile Detect component : ', error);
      });
  } else if (device.type !== 'unknown') {
    // we have a match so proceed
    app.lazyloadElements(pageId, device);
  }
};

/**
 * Sets the detected devices
 * Attempts immediate simple detection via platformDetection
 * Falls back to more accurate detection via lazy loaded platformMobileDetect using MobileDetect
 */
const setDevice = (detectedDevice) => {
  device = detectedDevice;
  TurnerToons.device = device.type;
  TurnerToons.user_platform = device.userPlatform;
  TurnerToons.isIE = device.isIE;
  if (domReady) {
    checkDevice();
  }
};

/**
 * This will only add service worker check if setup via project vars
 */
const addServiceWorker = () => {
  if (__SERVICE_WORKER__) {
    import(/* webpackChunkName: "service-worker" */ './utils/service-worker')
      .then(({ default: setupServiceWorker }) => {
        const log = getLogOptions({ id: 'serviceWorker' });
        setupServiceWorker(log, window.TurnerToons.languageCode);
      })
      .catch((error) => {
        console.error('An error occurred while loading the service-worker component : ', error);
      });
  }
};

handleDOMReady(() => {
  if (!__WATCH__) {
    // load in the deferred CSS
    let addStylesNode = document.getElementById('deferred-styles');
    if (addStylesNode && addStylesNode.textContent) {
      let replacement = document.createElement('div');
      replacement.innerHTML = addStylesNode.textContent;
      document.body.appendChild(replacement);
      addStylesNode.parentElement.removeChild(addStylesNode);
    }
  }

  log && console.log(`${prep} DOM Ready | PAGE ID ${pageId}`, logClr);
  domReady = true;
  app.init();
  checkDevice();
  addServiceWorker();
});

export const startApp = (initialiseMedia = false) => {
  const cookiePolicy = bootstrap.getCookiePolicy();
  log && console.log(`${prep} START APP | initialiseMedia ${initialiseMedia} | cookiePolicy`, logClr, { ...cookiePolicy });
  app.setReady(cookiePolicy, initialiseMedia);
  if (initialiseMedia) {
    bootstrap.initialiseMedia(device, cookiePolicy);
  }
};

/**
 * kickstart the bootstrap
 */
function start() {
  window.postscribe = postscribe;
  window.bitmovinPlayer = null;
  window.TurnerToons = window.TurnerToons || {};
  setDevice(getDevice(TurnerToons.platforms));
  bootstrap.start(pageId);
}

start();
